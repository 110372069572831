import { message, Space, Spin } from 'antd';
import { BlockLoader } from 'components/atoms/BlockLoader';
import { LoaderWithMessage } from 'components/common/LoaderWithMessage';
import { EditTypeControls } from 'components/molecules/EditTypeControls';
import { TypesForm } from 'components/organisms/TypesForm';
import { FormikProvider, useFormik } from 'formik';
import { EquipmentReturnTypePayload } from 'models/EquipmentReturnTypes';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetEquipmentReturnConfigurationTypeQuery, useUpdateEquipmentReturnConfigurationTypeMutation } from 'redux/services/maurice/sparingRequestConfigTypes';
import { setIsExit } from 'redux/slices/appSlice';
import { useAppSelector } from 'redux/store';

export const EditTypesPage = (): JSX.Element => {
  const { divisionId, typeId } = useParams();
  const navigate = useNavigate();
  const { isExit } = useAppSelector((state) => state.app);
  const { equipmentReturnTypesParams } = useAppSelector((state) => state);
  const dispatch = useDispatch();

  const [triggerUpdate, { isLoading: isCreating }] = useUpdateEquipmentReturnConfigurationTypeMutation();

  const { data, isLoading, isFetching } = useGetEquipmentReturnConfigurationTypeQuery(
    { divisionId: divisionId as string, typeId: typeId as string, params: equipmentReturnTypesParams },
    { skip: !typeId || !divisionId }
  );

  const formik2 = useFormik<EquipmentReturnTypePayload>({
    enableReinitialize: true,
    // validationSchema: requestTypePayload,
    initialValues: {
      description: data?.description ?? '',
      isActive: data?.isActive ?? true,
      name: data?.name ?? '',
      allowManualAddressEntry: data?.allowManualAddressEntry ?? false,
      allowManualAddressToPostOffice: data?.allowManualAddressToPostOffice ?? false,
      equipmentReturnRuleTypeName: data?.equipmentReturnRuleTypeName ?? '',
      displayAddressesByDefault: data?.displayAddressesByDefault ?? false,
      carrierAccountNumber: data?.carrierAccountNumber ?? '',
      carrierName: data?.carrierName ?? '',
      warehouseRoutingPriorities: data?.warehouseRoutingPriorities ?? [],
      isDeleted: data?.isDeleted ?? false,
      requestSource: data?.requestSource ?? []
    },

    onSubmit: async (values) => {
      try {
        if (!divisionId) return message.error('Division cannot be found');
        if (!typeId) return message.error('Type cannot be found');
        const newType = await triggerUpdate({ divisionId, equipmentReturnTypeId: typeId as string, payload: values }).unwrap();

        message.success(`${newType.name} successfully updated`);

        if (isExit) {
          navigate(`/${divisionId}/edit`);
          dispatch(setIsExit(false));
        } else {
          navigate(`/${divisionId}/types/${newType.id}/edit`);
        }
      } catch (err) {
        dispatch(setIsExit(false));
        console.log(err);
        message.error((err as { data: { errorMessage: string } }).data.errorMessage);
      }
    }
  });

  if (isLoading || isFetching) return <LoaderWithMessage loadingMessage="Loading equipment return type" />;

  return (
    <FormikProvider value={formik2}>
      <Spin spinning={isCreating} indicator={<BlockLoader message="Updating equipment return type" direction="loader loader--slideUp" />}>
        <Space direction="vertical" style={{ width: '100%' }}>
          <EditTypeControls />
          <TypesForm />
        </Space>
      </Spin>
    </FormikProvider>
  );
};
