import { skipToken } from '@reduxjs/toolkit/dist/query';
import { Card, Col, Form, Row, Space, Typography } from 'antd';
import { toRgba, userFormColors } from 'common/styles/colors';
import { EquipmentReturnLabels } from 'components/atoms/AllowedRuleTypeSelect';
import { AddressesCard } from 'components/molecules/AddressesCard';
import { ShipmentsCard } from 'components/molecules/ShipmentsCard';
import { WarehouseRoutingPriorityTable } from 'components/molecules/WarehousePriorityTable';
import { FieldInputName } from 'components/UI 2/FormItems/FieldInputName';
import { FieldInputSelect } from 'components/UI 2/FormItems/FieldInputSelect';
import { RequestSourceSelect } from 'components/UI 2/FormItems/RequestSourceSelect';
import { SwitchInputCard } from 'components/UI 2/FormItems/SwitchInputCard';
import { TextAreaInput } from 'components/UI 2/FormItems/TextAreaInput';
import { useParams } from 'react-router-dom';
import { useGetEquipmentReturnConfigurationQuery } from 'redux/services/maurice/equipmentReturnConfig';
import { useGetEquipmentReturnConfigurationTypeQuery } from 'redux/services/maurice/sparingRequestConfigTypes';

export const TypesForm = (): JSX.Element => {
  const { divisionId, typeId } = useParams();
  const { data: typeData } = useGetEquipmentReturnConfigurationTypeQuery({ divisionId: divisionId as string, typeId: typeId as string, params: {} }, { skip: !typeId || !divisionId });
  const { data } = useGetEquipmentReturnConfigurationQuery(divisionId ?? skipToken);

  const options = data?.allowedEquipmentReturnTypes.map((type) => ({ label: EquipmentReturnLabels[type as keyof typeof EquipmentReturnLabels], value: type }));

  return (
    <Row gutter={[16, 16]}>
      <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={6}>
        <Space direction="vertical" style={{ width: '100%' }} size={16}>
          <AddressesCard />
          <ShipmentsCard />
        </Space>
      </Col>
      <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={6}>
        <Card
          style={{ height: '100%' }}
          styles={{ header: { background: toRgba(userFormColors.royalBlueLight, 0.2) } }}
          title={
            <Typography.Title level={4} style={{ fontWeight: 400, fontSize: 22, margin: 0 }}>
              General
            </Typography.Title>
          }>
          <Row justify={'space-between'}>
            <Col span={24}>
              <Form layout="vertical">
                <Space direction="vertical" style={{ width: '100%' }} size={15}>
                  <Col>
                    <FieldInputName fieldName="name" label="Name" />
                  </Col>
                  <Col>
                    <FieldInputSelect fieldName="equipmentReturnRuleTypeName" label="Equipment Return Type" options={options} />
                  </Col>
                  <Col>
                    <RequestSourceSelect fieldName="requestSource" label="Request Sources" typeData={typeData} />
                  </Col>
                  <Col>
                    <TextAreaInput fieldName="description" label="Description" />
                  </Col>
                  <SwitchInputCard fieldName="isActive" label="Is Active" />
                </Space>
              </Form>
            </Col>
          </Row>
        </Card>
      </Col>

      <Col span={12} xs={24} sm={24} md={24} lg={24} xl={24} xxl={12}>
        <WarehouseRoutingPriorityTable />
      </Col>
    </Row>
  );
};
