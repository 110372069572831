import * as yup from 'yup';
import { DivisionMetadata } from './EquipmentReturnConfig';

export type EquipmentReturnType = {
  auditHistory: string[];
  createdByFullName: string;
  createdByUserId: string;
  createdDateTime: Date;
  equipmentReturnRuleTypeName: string;
  description: string;
  division: DivisionMetadata;
  documentType: string;
  id: string;
  isActive: boolean;
  isDeleted: boolean;
  allowManualAddressEntry: boolean;
  allowManualAddressToPostOffice: boolean;
  displayAddressesByDefault: boolean;
  modifiedByFullName: string;
  modifiedByUserId: string;
  modifiedDateTime: Date;
  name: string;
  partitionKey: string;
  partitionKeyDescription: string;
  warehouseRoutingPriorities: WarehouseRoutingPriority[];
  carrierName?: string;
  carrierAccountNumber?: string;
  requestSource: string[];
};

export type WarehouseRoutingPriority = {
  priority?: number;
  countryCode: string;
  subdivisionCode?: string;
  postalCode?: string;
  warehouseId: string;
};

export type EquipmentReturnTypePayload = {
  equipmentReturnRuleTypeName: string;
  description: string;
  isActive: boolean;
  isDeleted: boolean;
  allowManualAddressEntry: boolean;
  allowManualAddressToPostOffice: boolean;
  displayAddressesByDefault: boolean;
  carrierName?: string;
  carrierAccountNumber?: string;
  name: string;
  requestSource: string[];
  warehouseRoutingPriorities: WarehouseRoutingPriority[];
};

export interface WarehouseFulfillmentRules {
  id?: string;
  countryCode?: string;
  warehouseId?: string;
  warehouseType?: string;
  priority?: number;
  subdivisionCode?: string;
}
export interface DimensionFulfillmentRules {
  id?: string;
  conditionId?: string;
  dispositionId?: string;
  isDefault?: boolean;
  isCommonStock?: boolean;
  ownerId?: string;
  priority?: number;
}

export interface CreateSparingRequestType {
  name: string;
  inventoryReservationMethod: string;
  salesOrderType: string;
  description?: string;
  isActive?: boolean;
  isOverageAllowed?: boolean;
  nbdWarehouseFulfillmentRules?: WarehouseFulfillmentRules[];
  nfoWarehouseFulfillmentRules?: WarehouseFulfillmentRules[];
  dimensionFulfillmentRules?: DimensionFulfillmentRules[];
  isReleaseOrderRequired?: boolean;
  isLabConfigurationRequired?: boolean;
  isExpediteApprovalRequired?: boolean;
  isNextBusinessDayEnabled?: boolean;
  isNextFlightOutEnabled?: boolean;
  isLocalDeliveryEnabled?: boolean;
}

export interface TransportationLanes {
  id?: string;
  auditHistory?: string[];
  minimumDaysTransport?: number;
  minimumServiceDaysExpedite?: number;
  minimumServiceDaysStandard?: number;
  priority?: number;
  deliveryMode: string;
}

export interface SparingRequestTypesParams {
  isOverageAllowed?: boolean;
  nameContains?: string;
  salesOrderTypeEquals?: string;
  dispositionRuleTypeEquals?: string;
  includeInactiveData?: boolean;
  returnInactiveDataOnly?: boolean;
  includeDeletedData?: boolean;
  returnDeletedDataOnly?: boolean;
}

export const requestTypePayload: yup.SchemaOf<CreateSparingRequestType> = yup.object({
  name: yup.string().label('Name').required(),
  inventoryReservationMethod: yup.string().label('').required(),
  salesOrderType: yup.string().label('Sales Order Type').required(),
  description: yup.string().label('Description').notRequired(),
  isActive: yup.boolean().label('Is Active').notRequired(),
  isOverageAllowed: yup.boolean().label('Is Overage Allowed').notRequired(),
  nfoWarehouseFulfillmentRules: yup.array().label('NBD Fulfillment Rules'),
  nbdWarehouseFulfillmentRules: yup.array().label('NFO Fulfillment Rules'),
  dimensionFulfillmentRules: yup.array().label('Dimension Fulfillment Rules').min(1).required(),
  transportationLanes: yup.array().label('transportationLanes'),
  isLabConfigurationRequired: yup.boolean().label('Is Lab Configuration Required').notRequired(),
  isExpediteApprovalRequired: yup.boolean().label('Is Expedite Approval Required').notRequired(),
  isLocalDeliveryEnabled: yup.boolean().label('Is Local Delivery Enabled').notRequired(),
  isNextBusinessDayEnabled: yup.boolean().label('Is NBD Enabled').notRequired(),
  isNextFlightOutEnabled: yup.boolean().label('Is NFO Enabled').notRequired(),
  isReleaseOrderRequired: yup.boolean().label('Is Release Order Required').notRequired()
});

export const warehousePrioritySchema: yup.SchemaOf<WarehouseRoutingPriority> = yup.object({
  countryCode: yup.string().label('Country').required(),
  warehouseId: yup.string().label('Warehouse').required(),
  subdivisionCode: yup.string().label('State').notRequired(),
  priority: yup.number().label('Priority').notRequired(),
  postalCode: yup.string().label('Postal Code').required().notRequired()
});
