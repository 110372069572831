import { PlusOutlined } from '@ant-design/icons';
import { Button, Col, Divider, Input, notification, Row, Select, SelectProps } from 'antd';
import { Field, FieldProps, useFormikContext } from 'formik';
import { EquipmentReturnType, EquipmentReturnTypePayload } from 'models/EquipmentReturnTypes';
import { CSSProperties, FC, useState } from 'react';
import { FormLabel } from './FormLabel';

interface Props extends SelectProps {
  fieldName: string;
  label: string;
  labelStyle?: CSSProperties;
  typeData?: EquipmentReturnType;
}

export const RequestSourceSelect: FC<Props> = ({ fieldName, label, labelStyle, typeData, ...rest }) => {
  const { setValues, values } = useFormikContext<EquipmentReturnTypePayload>();
  const [name, setName] = useState('');
  const options = values?.requestSource.map((val) => ({ label: val, value: val }));

  const addItem = (val: string): void => {
    if (values.requestSource.includes(val)) {
      notification.error({ message: 'Source already exists' });

      return;
    }
    setValues((prev) => {
      return {
        ...prev,
        requestSource: [...values.requestSource, val]
      };
    });
    setName('');
  };

  return (
    <Field name={fieldName}>
      {({
        field, // { name, value, onChange, onBlur }
        form: { setFieldTouched, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
        meta
      }: FieldProps): JSX.Element => {
        // const options = (field.value as string[]).map((val) => ({ label: val, value: val }));

        const handleChange = (val: string): void => {
          setFieldValue(fieldName, val);
        };

        return (
          <Row style={{ width: '100%' }}>
            <FormLabel style={labelStyle} label={label} />

            <Select
              {...field}
              showSearch
              allowClear
              onClear={(): void => {
                setFieldTouched(fieldName, true);
              }}
              filterOption={(input, option): boolean => {
                const label = typeof option?.label === 'string' ? option.label.toLowerCase() : '';

                return label.toLowerCase().includes(input.toLowerCase()) ?? false;
              }}
              onChange={handleChange}
              status={meta.error && meta.touched ? 'error' : undefined}
              onFocus={(): void => setFieldTouched(fieldName, false)}
              onBlur={(): void => setFieldTouched(fieldName, true)}
              style={{ width: '100%' }}
              mode="multiple"
              options={options}
              dropdownRender={(menu): JSX.Element => (
                <>
                  {menu}
                  <Divider style={{ margin: '8px 0' }} />
                  <Row gutter={[5, 5]}>
                    <Col span={16}>
                      <Input value={name} placeholder="Source Name" onKeyDown={(e): void => e.stopPropagation()} onChange={(event): void => setName(event.target.value)} />
                    </Col>
                    <Col span={8}>
                      <Button type="text" icon={<PlusOutlined />} onClick={(): void => addItem(name)}>
                        Add Source
                      </Button>
                    </Col>
                  </Row>
                </>
              )}
              {...rest}
            />
            <div className={meta.touched && meta.error ? 'field-error-show' : 'field-error-hide'}>{meta.error}</div>
          </Row>
        );
      }}
    </Field>
  );
};
